import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant'
import 'vant/lib/index.css';
import './assets/css/iconfont.css'

//移动端的单位设置【rem基数：37.5，PC：750px 移动端75px 37.5rem】
import 'lib-flexible/flexible.js';
import './assets/css/reset.css'

//当前的axios模块引用的是自定义拦截器模块
import axios from './utils/interceptor/';
//接口前置URL
axios.defaults.baseURL = process.env.VUE_APP_API_URL

import VueAxios from 'vue-axios';
// axios.defaults.baseURL = '/api/';//前置地址


import qs from 'qs';

//自定义公共文件
import Common from './utils/Common';


const app = createApp(App);
app.config.globalProperties.Common = Common;
app.config.globalProperties.qs = qs;
app.use(vant).use(VueAxios,axios).use(router).mount('#app')
