<template>
	<div class="main">
		<div class="head" v-if="store">
			<div class="left">
				<van-image round width="1.2rem" height="1.2rem" :src="store['avatar']" />
			</div>
			<div class="center">
				<span>{{store['nickName']}}</span>
				<p>{{store['signature']}}</p>
			</div>
			<div class="right">
				<svg class="icon" aria-hidden="true" @click="showStoreQrImg">
				  <use xlink:href="#icon-erweima"></use>
				</svg>
			</div>
		</div>
		<van-swipe class="swipe" :autoplay="2000" indicator-color="white">
		   <van-swipe-item v-for="item,index in swipe" :key="index">
		      <img :src="item['image']" @click="clickSwipe(index)" fit="cover" style="border-radius: 10px;overflow: hidden;display: block;"/>
		    </van-swipe-item>
		</van-swipe>
		
		<van-grid :column-num="3" :border="false" icon-size="29">
			<van-grid-item @click="$router.push('query')">
			    <svg class="icon" aria-hidden="true">
			      <use xlink:href="#icon-yuyuechaxun"></use>
			    </svg>
				<p>订单查询</p>
			</van-grid-item>
			<van-grid-item @click="open('https://getsimnum.caict.ac.cn/m/#/')">
			    <svg class="icon" aria-hidden="true">
			      <use xlink:href="#icon-chaxun"></use>
			    </svg>
				<p>一证通查</p>
			</van-grid-item>
			<van-grid-item @click="showKf()">
			    <svg class="icon" aria-hidden="true">
			      <use xlink:href="#icon-lianxikefu"></use>
			    </svg>
				<p>联系客服</p>
			</van-grid-item>
		</van-grid>
		
		<van-tabs :animated="true">
		  <van-tab  v-for="title,index1 in ['电信','移动','联通','广电']" :title="title" :key="index1">
			  <!-- 卡列表 -->
			  <van-list v-model:loading="loading" :finished="finished" @load="onLoad" finished-text="到底了～" style="overflow: hidden;">
			    <div class="card" v-for="item,index2 in ProductList[index1]" :key="index2" @click="jumpDatails(item['id'])">
					<!-- 左侧图像 -->
					<div class="card-img">
						<img :src="item['img']" style="height: 100%;" fit="cover"/>
					</div>
					<!-- 右侧卡信息 -->
					<div class="card-info">
						<p style="font-size: 0.3rem;">{{item['name']}}</p>
						<!-- 流量信息 -->
						<div style="text-align: center;font-size: 0.28rem;padding: 10px 0;display: flex;justify-content: center;align-items: center;">
							<span>通用流量</span>
							<svg class="icon2" aria-hidden="true">
							  <use xlink:href="#icon-tangguobang"></use>
							</svg>
							<span>定向流量</span>
							<svg class="icon2" aria-hidden="true">
							  <use xlink:href="#icon-tangguobang"></use>
							</svg>
							<span>通话时长</span>
						</div>
						<div style="text-align: center;font-size: 0.45rem;font-weight:700;padding: 10px 0;display: flex;justify-content: center;align-items: center;">
							<span style="padding: 0 0.2rem;color: #35b618;">{{item['gFlows']}}G</span>
							<span style="padding: 0 0.3rem;color: #ddd;">|</span>
							<span style="padding: 0 0.2rem;color: #e84167;">{{item['dFlows']}}G</span>
							<span style="padding: 0 0.3rem;color: #ddd;">|</span>
							<span style="padding: 0 0.2rem;color: #f36b00;">{{item['callDuration']}}分</span>
						</div>
						<div style="position: absolute;bottom: 5px;right: 30px;color: #7782ff;">
							<span style="padding-right: 20px;">归属地:[{{item['area']}}]</span>
							<span v-if="item['age']">适龄:{{item['age']}}</span>
						</div>
					</div>
				</div>
			  </van-list>
		  </van-tab>
		  
		</van-tabs>
		<van-dialog v-model:show="show[0]" confirmButtonText="长按保存图片">
		  <img :src="store['qrCode']" />
		</van-dialog>
		<van-dialog v-model:show="show[1]" confirmButtonText="长按保存图片">
		  <img :src="storeQRcode" />
		</van-dialog>
	</div>
	<div class="beian">
		<div style="display: flex;justify-content: center;align-items: center">
			<a href="https://beian.miit.gov.cn/">粤ICP备2021162645号-3</a>
			<img src="../assets/images/beian.png" alt="备案图标" style="width: 15px;margin:0 2px 0 15px;"/>
			<a href="https://beian.mps.gov.cn/#/query/webSearch?code=44060402002768" rel="noreferrer" target="_blank">粤公网安备44060402002768</a>
		</div>
	</div>
</template>

<script>
import '../../src/assets/js/iconfont.js'
import { showLoadingToast,closeToast,showDialog} from 'vant';
var that;
export default {
	components: {
		
	},
	async created() {
		that = this;
		let Code = this.$route.params['code']
		if(!Code)Code=window.localStorage.getItem("Code");
		if(Code){
			that.code = Code
			window.localStorage.setItem("Code", Code);
			await that.getStore()
			await that.Reload();
		}
	},
	data() {
		return {
			swipe:[],
			loading:false,
			finished:true,
			show:[false,false],
			code:'',
			page:1,
			size:15,
			store:null,
			storeQRcode:null,
			ProductList:[[],[],[],[]],
			
		}
	},
	methods:{
		async Reload(){
			showLoadingToast({
				message: '加载中...',
				forbidClick: true,
			});
			let params = {
				code: that.code,
				page: that.page,
				size: that.size
			}
			var GetProductList = await this.axios.get('h5/GetProductList?'+this.qs.stringify(params))
			if(GetProductList['data']['ret']==200){
				GetProductList['data']['list'].forEach((item)=>{
					var age = eval(item['age']);
					if(age.length==2){
						item['age'] = age[0]+ "-" + age[1]
					}else{
						item['age'] = null;
					}
					that.ProductList[item['operator']-1].push(item);
				})
				if(that.page>=Math.ceil(GetProductList['data']['total']/GetProductList['data']['size'])){
					that.finished=true;
				}else{
					that.finished=false;
				}
			}
			closeToast()
			that.loading=false;
		},
		async getStore(){
			showLoadingToast({
				message: '加载中...',
				forbidClick: true,
			});
			that.loading=true;
			let params = {
				code: that.code,
			}
			var Store = await this.axios.get('H5/Store?'+this.qs.stringify(params))
			if(Store['data']['ret']==200){
				that.store = Store['data']['data']
				that.swipe = Store['data']['swipe']
			}else{
				that.store = Store['data']['store']
			}
			document.title = that.store['nickName'] || "号卡多"
			closeToast()
			that.loading=false;
		},
		onLoad(){
			that.page++;
			that.Reload();
		},
		//下拉刷新
		onRefresh(){
			setTimeout(() => {
				this.loading = false;
			}, 1000);
		},
		jumpDatails(goodsId){
			that.$router.push({
				name:"Details",
				query:{
					id: goodsId
				}
			})
		},
		open(url){
			window.open(url)
		},
		showKf(){
			if(this.store['qrCode']){
				this.show[0] = true;
			}else{
				showDialog({
				  message: '暂无联系方式!',
				  theme: 'round-button',
				  showConfirmButton:false,
				  showCancelButton:true,
				  cancelButtonText:"知道了"
				})
			}
		},
		clickSwipe(index){
			if(that.swipe[index]['url']){
				window.location.href = that.swipe[index]['url']
			}
		},
		async showStoreQrImg(){
			showLoadingToast({
				message: '图片加载中...',
				forbidClick: true,
			});
			let params = {
				type:1,
				sellCode: that.code,
			}
			var result = await this.axios.get('GetQRcode?'+this.qs.stringify(params))
			if(result['data']['ret']==200){
				that.storeQRcode = result['data']['img'];
				this.show[1] = true;
			}
			closeToast()
		}
	}
}
</script>
<style scoped>
.head{
	position: relative;
	width: 96%;
	margin: 10px auto;
	display: flex;
	background: linear-gradient(145deg, #e1e7fb, #e0ebf3);
	border-radius: 10px;
	padding: 10px 0;
}
.head .left{
	display: flex;
	justify-content: center;
	width: 20%;
}
.center span{
	font-size: 0.4rem;
	font-weight: bold;
	line-height: 0.7rem;
}
.center p{
	color: #636363;
	line-height: 0.5rem;
}
.right{
	flex: 1;
}
.right svg{
    position: absolute; /* 设置为绝对定位 */
    right: 0; /* 距离右边界为零 */
    top: 50%; /* 相对于父容器的高度的50%处 */
    transform: translateY(-50%); /* 向上移动自身高度的50%，使得元素在垂直方向上居中 */
	margin-right: 20px;
}
.main{
	/* width: 96%;
	margin: 10px auto; */
}

/* 轮播图 */
.swipe{
	width: 96%;
	margin: 10px auto;
	border-radius: 10px;
	overflow: hidden;
}

:deep(.van-swipe__indicator){
	width: 10px;
	height: 2px;
	border-radius: 0;
}
:deep(.van-list__finished-text){
	font-size: 0.25rem;
}
/* 卡片 */
.card{
	width: 96%;
	padding: 5px 0;
	margin: 10px auto;
	max-height: 120px;
	border-radius: 10px;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	box-shadow: 8px 5px 20px #e9e6ec;
}
.card .card-img{
	width: 35%;
}
.card-img img{
	display: block;
}
.card-info{
	width: 63%;
	padding-top: 10px;
	position: relative;
}
.icon {
  width: 3.5em;
  height: 3.5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  margin-bottom: 7px;
}
.icon2 {
  width: 1.5em;
  height: 1.5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  margin: 0 8px;
}
.beian{
	height: 50px;
}
</style>
