<template>
	<div class="main">
		<div class="content" v-if="orderList.length==0">
			<div class="forms">
				<p style="font-size: 0.8rem;text-align: center;line-height: 2rem;">订单查询</p>
				<van-form @submit="onSubmit">
				  <van-cell-group inset>
				    <van-field
				      v-model="uForm.idcard"
				      name="idcard"
					  maxlength="4"
				      label="身份证尾号"
				      placeholder="办理人身份证号后4位"
				      :rules="[{ required: true, message: '请填写办理人身份证号后4位' }]"
				    />
				   <van-field
					 v-model="uForm.phone"
					 name="phone"
					 type="tel"
					 maxlength="11"
					 label="联系号码"
					 placeholder="收件人联系号码"
					 :rules="[{ required: true, message: '请填写联系号码' }]"
				   />
						   
				  </van-cell-group>
				  <div style="margin: 16px;">
				    <van-button round block type="primary" native-type="submit">查询</van-button>
					<van-button v-if="showback" round hairline block type="info" native-type="button" style="margin-top: 10px;" @click="back">返回</van-button>
				  </div>
				</van-form>
			</div>
		</div>
		<div class="list" v-if="orderList.length>0">
			<div class="list-header">
				<span style="line-height: 20px;font-size: 0.3rem;">共查询到<span style="color: coral;">{{orderList.length}}条</span>订单：</span>
				<van-button plain type="primary" size="mini" @click="clearList">清除结果</van-button>
			</div>
			<van-list v-model:loading="loading[0]" :finished="true" >
				<div class="cardItem" v-for="item in orderList" :key="item['id']" @click="showInfo(item['id'])">
					<div class="cup">
						<b>{{item['id']}}<van-icon name="arrow" /></b>
						<van-tag round :type="item['status']==4?'danger':item['status']==7?'success':'primary'" size="medium">
							{{Common.getOrderStatus(item['status'])}}
						</van-tag>
					</div>
					<div class="half-pixel-line"></div>
					<p><van-icon class="ccolor" name="goods-collect" />{{item['goodsName']}}</p>
					<p><van-icon class="ccolor" name="clock" />{{Common.formatDate(item['createTime'])}}</p>
					<p v-if="item['logistics']">
						<van-icon class="ccolor" name="bell" />{{item['logistics']}} - {{item['logisticsNumber']}}
					</p>
					<p v-if="item['status']==4" style="font-size: 0.3rem;color: #b10000;">
						原因：{{item['notes']}}
					</p>
				</div>
			</van-list>
		</div>
		<!-- 详情 -->
		<van-action-sheet v-model:show="show[0]" title="订单详情">
			<div class="orderInfo">
				<van-cell-group>
					<van-cell title="订单编号" :value="data['id']" style="font-size: 0.32rem;"/>
					<van-cell title="订购商品" :value="data['goodsName']" />
					<van-cell title="下单时间" :value="data['createTime']" />
					<van-cell title="更新时间" :value="data['updateTime']" />
					<van-cell title="姓名"    :value="data['userName']" />
					<van-cell title="身份证号" :value="data['userNumber']" />
					<van-cell v-if="data['selectedNumber']" title="办理号码" :value="data['selectedNumber']" />
					<van-cell title="收货地址" :value="data['userAddress']" />
					<van-cell title="订单状态" :value="data['status']"/>
					<van-cell v-if="data['notes']" title="备注" :value="data['notes']"/>
					<van-cell v-if="data['logistics']" title="承运物流" :value="data['logistics']" />
					<van-cell v-if="data['logisticsNumber']" title="物流单号" :value="data['logisticsNumber']" />
				</van-cell-group>
				<van-cell-group title="物流详情" v-if="show[1]">
					<van-steps  direction="vertical" :active="0">
					  <van-step v-for="(activity, index) in logistics" :key="index">
						<h3>{{activity.content}}</h3>
						<p>{{activity.time}}</p>
					  </van-step>
					</van-steps>
				</van-cell-group>
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
var that = null;
import { showLoadingToast,closeToast,showDialog } from 'vant';
import Common from '../../src/utils/Common'
export default {
	name: 'Query',
	components: {
		
	},
	data() {
		return {
			uForm:{
				idcard:"",
				phone:""
			},
			loading:[false],
			show:[false,false],
			orderList: [],
			data:[],
			showback:false,
			logistics:[],//物流详情
		}
	},
	created() {
		that = this;
		if(window.history.length > 1){
			this.showback = true;
		}
		that.orderList = []
	},
	methods:{
		async onSubmit(values){
			this.loading[0]=true;
			showLoadingToast({
				message: '查询中...',
				forbidClick: true,
			});
			//提交订单
			let params = {
				tail:this.uForm.idcard,
				phone:this.uForm.phone
			}
			const result = await this.axios.get('H5/QueryOrder?'+this.qs.stringify(params))
			closeToast()
			if(result['data']['ret']==200){
				that.orderList = result['data']['list']
			}else{
				showDialog({
				  message: result['data']['msg'],
				})
			}
			this.loading[0]=false;
		},
		showInfo(gid){
			this.logistics = [];
			this.data = {...this.orderList.find(obj=>obj['id']==gid)}
			this.data['createTime'] = Common.formatDate(this.data['createTime']);
			this.data['updateTime'] = Common.formatDate(this.data['updateTime']);
			this.data['status'] = Common.getOrderStatus(this.data['status']);
			that.show[0] = true;
			if(this.data['logistics'])this.Querylogistics(gid);
		},
		async Querylogistics(id){
			var result = await this.axios.get('User/Querylogistics?id='+id)
			if(result['data']['ret']==200){
				this.show[1] = true;
				result.data.list.forEach(element => {
					element.time = Common.formatDate(element.time)
					this.logistics.push(element)
				})
			}
		},
		clearList(){
			that.orderList = [];
		},
		back(){
			this.$router.back(-1);
		// 	console.log(window.history);
		// 	// 检查浏览器历史记录长度
		//     if (window.history.length > 1) {
		//       // 如果有上一页，执行$router.back(-1)
		//       this.$router.back(-1);
		//     } else {
		//       // 如果没有上一页，跳转到指定页面，例如 '/home'
		//       this.$router.push('Home');
		//     }
		}
	}
}
</script>

<style scoped>
	
.main{
	/* max-width: 10.2rem; */
	height: 100vh;
	/* display: flex; */
	/* justify-content: center; */
	/* align-items: center; */
	/* flex-direction: column; */
	background-color: #f3f3f3;
	position: relative;
}

.content{
	height: 100%;
	display: flex;
	align-items: center; /* 垂直居中 */
	justify-content: center; /* 水平居中，如需要 */
}
.forms{
	width: 92%;
	background-color: #f8f8f8;
	text-align: center;
	color: #3d85f9;
	padding: 30px 10px;
	border-radius: 10px;
	box-shadow: 0px 0px 20px #d5eef2;
}
.list{
	width: 90%;
	overflow-y: auto;
	margin: 0 auto;
}
.list-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 50px;
}
.cardItem{
	font-size: 0.35rem;
	color: #2e2063;
	background: white;
	margin: 10px 0;
	padding: 10px 0;
	border-radius: 10px;
	overflow: hidden;
}
.cup{
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}
.half-pixel-line {
  width: 100%;
  height: 1px; /* 初始线条高度为1px */
  background-color: #f5a4cd; /* 线条颜色 */
  transform: scaleY(0.5); /* 垂直方向缩放至0.5 */
  transform-origin: 0 0; /* 缩放基点设置为左上角 */
  margin-bottom: 5px;
}
.cardItem p{
	padding: 0 10px;
	line-height: 0.6rem;
}
.ccolor{
	color: #ffa400;
	margin-right: 3px;
}
</style>