import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/:code?',
		name: 'Home',
		meta:{
			title : "号卡多",
		},
		component: () => import('../views/Home.vue')
	},
	{
		path: '/query',
		name: 'Qurey',
		meta:{
			title : "订单查询",
		},
		component: () => import('../views/Qurey.vue')
	},
	{
		path: '/details',
		name: 'Details',
		meta:{
			title : "商品详情",
		},
		component: () => import('../views/Details.vue')
	},
	{
		path: '/success',
		name: 'Success',
		meta:{
			title : "订购成功",
		},
		component: () => import('../views/Success.vue')
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 点击路由回到顶部
router.afterEach((to,from,next) => {
	window.scrollTo(0,0);
})

router.beforeEach((to, from, next) => {
	if (to.meta.title)document.title = to.meta.title
	next();
})

export default router
