<template>
	<div class="main" v-if="product">
		<div class="winBox">
			<img :src="product['img']" fit="cover"/>
			<!-- 信息面板 -->
			<div class="info">
				<!-- 月租 -->
				<div class="price">
					<span style="font-size: 0.3rem;">月租&nbsp;</span>
					<span style="font-size: 0.65rem;color: #e33300;font-weight: bold;">{{product['rental']}}</span>
					<span style="font-size: 0.3rem;">&nbsp;元</span>
				</div>
				<div class="proctName">{{product['name']}}</div>
				<div class="info2">
					<span>归属地：{{product['area']}}</span>
				</div>
			</div>
			<div class="info" style="display: flex;justify-content: space-between;">
				<span>通用流量：<van-tag color="#0088c4" text-color="#fff">{{product['gFlows']}}G</van-tag></span>
				<span>定向流量：<van-tag color="#e85100" text-color="#fff">{{product['dFlows']}}G</van-tag></span>
				<span>通话时长：<van-tag color="#00a500" text-color="#fff">{{product['callDuration']}}分</van-tag></span>
			</div>	
			<van-grid :column-num="3" :border="false" class="mian1" v-if="!hide">
				<van-grid-item @click="$router.push('query')">
				    <svg class="icon" aria-hidden="true">
				      <use xlink:href="#icon-yuyuechaxun"></use>
				    </svg>
					<p>订单查询</p>
				</van-grid-item>
				<van-grid-item @click="open('https://getsimnum.caict.ac.cn/m/#/')">
				    <svg class="icon" aria-hidden="true">
				      <use xlink:href="#icon-chaxun"></use>
				    </svg>
					<p>一证通查</p>
				</van-grid-item>
				<van-grid-item @click="showQrImg()">
				    <svg class="icon" aria-hidden="true">
				      <use xlink:href="#icon-fenxiang"></use>
				    </svg>
					<p>分享商品</p>
				</van-grid-item>
			</van-grid>
			<van-divider :style="{ color: '#696f6e', borderColor: '#d0d6d0', padding: '0 16px' }" >商品详情</van-divider>
			<div class="datails" v-html="product['details']"></div>
		</div>
		<div class="bottom" v-if="!hide">
			<div class="btn button-skew">
				<button @click="$router.push('/')">
					<span>首页</span>
					</button>
				<button @click="show[0]=true">
					<span>领取</span>
					</button>
			</div>
		</div>
	</div>
	<div class="error" v-else>
		<div class="error-main">
			<img src="../assets/images/error1.png" alt="" />
			<p style="color: #ae4049;margin-bottom: 30px;font-size: 0.4rem;">{{errText}}</p>
			<van-button type="default" size="small" @click="$router.back(-1)">返 回</van-button>
		</div>
	</div>
	<van-action-sheet v-model:show="show[0]" title="填写信息(已加密)" :close-on-click-overlay="false">
	   <van-form @submit="onSubmit">
	     <van-cell-group inset>
	       <van-field
	         v-model="uForm.username"
	         name="username"
	         label="姓名"
	         placeholder="请输入办理人姓名"
	         :rules="[{ required: true, message: '请填写办理人姓名' }]"
	       />
	       <van-field
	         v-model="uForm.idcard"
	         name="idcard"
	         label="身份证"
	         placeholder="请输入办理人身份证号"
	         :rules="[{ required: true, message: '请填写身份证号' }]"
	       />
		   <van-field
		     v-model="uForm.phone"
		     type="phone"
		     name="phone"
		     label="联系号码"
		     placeholder="请输入收件人联系号码"
		     :rules="[{ required: true, message: '请填写联系号码' }]"
		   />
		   <van-field
		     v-model="selectedOptions"
		     is-link
		     readonly
		     name="area"
		     label="地区选择"
		     placeholder="点击选择省市区"
			 :rules="[{ required: true, message: '请选择地区' }]"
		     @click="show[1] = true"
		   />
		   <van-popup v-model:show="show[1]" position="bottom">
		     <van-area
		       :area-list="areaList"
		       @confirm="onConfirm"
		       @cancel="show[1] = false"
		     />
		   </van-popup>
			<van-field
			  v-model="uForm.address"
			  name="address"
			  label="详细地址"
			  placeholder="如街道,道路,小区,门牌"
			  :rules="[{ required: true, message: '请填写详细地址' }]"
			/>
	     </van-cell-group>
	     <div style="margin: 16px;">
	       <van-button round block type="primary" native-type="submit">
				提 交
	       </van-button>
	     </div>
	   </van-form>

	</van-action-sheet>
	<van-dialog v-model:show="show[2]" confirmButtonText="长按保存图片">
	  <img :src="GoodsQRcode" />
	</van-dialog>
</template>

<script>
import { showDialog,showLoadingToast,closeToast,showNotify } from 'vant';
import { areaList } from '@vant/area-data';
import Common from '../../src/utils/Common.js'
import '../../src/assets/js/iconfont.js'
var that;
export default {
	name: 'Orders',
	components: {
		
	},
	data() {
		return {
			product:null,
			errText:'加载中...',
			show:[false,false,false],//0=填写信息，1=地区选择 2=商品分享二维码
			areaList:[],
			selectedOptions:[],
			tip:'',
			uForm:{},
			code:'',
			GoodsQRcode:'',
			hide:false,
		}
	},
	created() {
		that = this;
		let id = that.$route.query['id']
		let hide = that.$route.query['hide']
		if(hide)that.hide = true;
		that.areaList = areaList;
		that.id = id;
		that.GetDatails();
	},
	methods:{
		async GetDatails(){
			let params = {id:that.id}
			const result = await this.axios.get('H5/Datails?'+this.qs.stringify(params))
			if(result['data']['ret']==200){
				let data = result['data']['data'];
				if(result['data']['sellCode']){
					that.code = result['data']['sellCode']
					window.localStorage.setItem("Code", result['data']['sellCode']);
				}
				window.document.title = "号卡多 - " + data['name'];
				data['details'] = atob(data['details']);
				that.product = data;
				that.tip = result['data']['tip'] || "即将提交"
			}else{
				that.errText = result['data']['msg']
			}
		},
		onConfirm( {selectedOptions} ) {
			that.show[1] = false;
			that.selectedOptions = selectedOptions.map((item) => item.text).join('-');
		},
		async onSubmit(values){
			var countdownObj = { countdown: 5 };
			var Time_ = setInterval(function() {
			  countdownObj.countdown--;
			  if (countdownObj.countdown <= 0) clearInterval(Time_);
			}, 1000);

			var area = values['area'].split("-");
			// console.log('submit', values);
			showDialog({
			  title: '温馨提示',
			  message: that.tip,
			  theme: 'round-button',
			  showCancelButton:true,
			  beforeClose: (action) => {
		        	if (action === 'confirm' && countdownObj.countdown>0) {
					  showNotify({ type: 'warning', message: '请认真阅读提示,请'+countdownObj.countdown+'秒再次点击确认' });
		          	 return false;
		        	}else{
					  clearInterval(Time_);
					  return true;
				  }
		      },
			}).then(async (action) => {
			    showLoadingToast({
					message: '加载中...',
					forbidClick: true,
			    });
			    //提交订单
				let params = {
					data:Common.myEncode(encodeURIComponent(JSON.stringify({...values}))),
					id:that.id
				}
				const result = await that.axios.post('H5/CreateOrder',params)
				closeToast()
				if(result['data']['ret']==200){
					that.show[0] = false;
					setTimeout(()=>{
						that.$router.push('Success')
					},300)
				}
				if(result['data']['ret']==-1){
					showDialog({
					  title: '提交失败',
					  message: result['data']['msg'],
					})
				}
			    
			});
		    
		},
		async showQrImg(){
			showLoadingToast({
				message: '图片加载中...',
				forbidClick: true,
			});
			let params = {
				type:2,
				id: that.id
			}
			var result = await this.axios.get('GetQRcode?'+this.qs.stringify(params))
			if(result['data']['ret']==200){
				that.GoodsQRcode = result['data']['img'];
				this.show[2] = true;
			}
			closeToast()
		},
		open(url){
			window.open(url)
		},
	}
}
</script>
<style scoped>
	.main{
		position: relative;
	}
.winBox{
	background-color: #f3f1f5;
	padding-bottom: 60px;
}
.custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 0.25rem;
	color: aliceblue;
    background: rgba(189, 189, 189, 0.5);
	border-radius: 10px;
}
.info{
	width: 95%;
	margin: 10px auto 0;
	padding: 10px;
	border-radius: 5px;
	background-color: #fff;
	position: relative;
}
.mian1{
	width: 95%;
	margin: 10px auto 0;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;
}
.icon {
  width: 2.5em;
  height: 2.5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  margin-bottom: 5px;
}
.proctName{
	font-size: 0.4rem;
	padding: 10px 0;
}
.bottom{
	max-width: 10.2rem;
	width: 100%;
	bottom: 10px;
	position: fixed;
	display: inline-block;
}
.bottom .btn{
	width: 90%;
	margin: 0 auto;
	position: relative;
}

.bottom .btn button{
	width: 50%;
	height: 30px;
	line-height: 30px;
	border: 0;
	overflow: hidden;
	color: white;
	background: linear-gradient(to right, #6d79ff, #ea5f49);
}
.bottom .btn button:last-child{
	border-left: solid 1px white;
	background: linear-gradient(to right, #ea5f49, #ee0a24);
}
.button-skew {
  transform: skewX(-20deg);
}
.button-skew span{
	display: inline-block;
	transform: skewX(20deg);
}
.info2{
	position: absolute;
	right: 10px;
	top: 10px;
}
.share{
	background-color: #6d79ff;
	height: 1rem;
	display: flex;
	align-items: center;
	position: absolute;
	right: 30px;
	top: 10px;
	color: #ff0000;
}
.error{
	max-width: 10.2rem;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.error-main{
	text-align: center;
	width: 50%;
}
</style>