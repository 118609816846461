<template>
	<div class="main">
		<div class="content">
			<van-icon name="checked" style="font-size: 3rem;color: #01c900;margin-bottom: 10px;"/>
			<p>订单已提交</p>
			<p>我们将会审核信息，通过后尽快为您安排邮寄。</p>
			<p style="margin: 80px 0 20px 0;">
				<van-button plain hairline type="success" size="normal" @click="$router.push('/')">返回首页</van-button>
			</p>
			<p>
				<van-button plain hairline type="primary" size="normal" @click="$router.push('/query')">查询订单</van-button>
			</p>
		</div>
	</div>
</template>

<script>
</script>

<style scoped>
	.main{
		max-width: 550px;
		margin: 0 auto;
		height: 70vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.content{
		text-align: center;
		height: 100px;
	}
	.content p{
		font-size: 0.35rem;
		line-height: 0.6rem;
		color: #0b90f5;
	}
</style>