import axios from 'axios';
// import router from '@/router/index'
import { showFailToast } from 'vant';

//是否允许跨域时携带cookie信息[允许]
axios.defaults.withCredentials = true;

let instance = axios.create();

// http响应拦截 response 拦截器
instance.interceptors.response.use(
  response => {
    //拦截响应，做统一处理
	switch(response['data']['ret']){
		case -1:
			// setTimeout(()=>{
			// 	showFailToast(response['data']['msg']);
			// },500)
		break;
		//登录信息失效
		case 201:
			showFailToast(response['data']['msg']);
			//ElMessage.error({message: response['data']['msg']});
			// router.push({
			// 	name: 'Login',
			// 	query: { redirect: window.location.pathname }
			// });//跳转登录页
		break;
		case 400:
			showFailToast("接口请求异常!");
		break;
		case 404:
			//ElMessage.error({message: response['data']['msg']});
		break;
	}
	return response
  },
  //错误处理	
  error => {
    switch(error.response.status){
		default:
			//ElMessage.error({message: '请求异常！'});
			showFailToast("接口请求异常!");
			console.log("请求异常！",error['request']['responseURL'],error['request']['responseText']);
		break;
	}
    return Promise.reject(error.response.status) // 返回接口返回的错误信息
  }
)

export default instance;//导出instance
