// 公共代码提供全局使用
//取自用加密算法Keys对应字符索引
function getEncodeIndex(type = 0, key = null, keys_array = null) {
	if (type === 0) {
		return "SQHUGOAVZIXCEFJMWNDYKLBTRP6908374251pzrfeyxbwsmghcinjadqkulotv/+=";
	} else {
		for (let i = 0; i < 65; i++) {
			if (keys_array[i] === key) {
				return i + 1;
			}
		}
		return 0;
	}
}
	
export default{
	//json格式转url格式
	converToUrl(Params){
		let params = [];
		  Object.entries(Params).forEach(([key, value]) => {
		      let param = key + '=' + value;
		      params.push(param);
		  });
		  return '?' + params.join('&');
	},
	//计算时间差天数
	DateDiff(time){
		let days = 0;
		let today = new Date();	
		let end = new Date(time*1000);
		if(end > today){
			days = parseInt(Math.abs(end - today) / 1000 / 60 / 60 / 24);
		}else{
			days = parseInt(Math.abs(end - today) / 1000 / 60 / 60 / 24); // 如果不限制对比时间和当前时间大小可以不用if
		}	
		return days;
	},
	//格式化时间
	formatDate(t){
		//时间戳为10位数时*1000
		if(t.toString().length==10)t = t*1000;
		let now = new Date(t);
		let year = now.getFullYear();
		let month = this.toNum(now.getMonth() + 1);
		let day = this.toNum(now.getDate());
		let hours = this.toNum(now.getHours());
		let min = this.toNum(now.getMinutes());
		let sec = this.toNum(now.getSeconds());
		return `${year}-${month}-${day} ${hours}:${min}:${sec}`;
	},
	toNum(t){
		return t>=10 ? t: '0'+t;
	},
	myEncode(data) {
	    let data_b64 = btoa(data);
	    let data_array = data_b64.split('');
	    let data_reversedArray = data_array.reverse();
	    let keys_array = getEncodeIndex(0);
	    let data_new_array = [];
	    for (let i = 0; i< data_reversedArray.length; i++) {
	        data_new_array.push(getEncodeIndex(1, data_reversedArray[i], keys_array));
	    }
	    let data_json_str = JSON.stringify(data_new_array);
	    let data_result = btoa(data_json_str);
	    return data_result;
	},
	getOrderStatus(stsus){
		var result = "未知";
		switch(stsus){
			case 1:
				result = "待处理";
			break;
			case 2:
				result = "开卡中";
			break;
			case 3:
				result = "待证件上传";
			break;
			case 4:
				result = "开卡失败";
			break;
			case 5:
				result = "已发货";
			break;
			case 6:
				result = "待激活";
			break;
			case 7:
				result = "已激活";
			break;
		}
		return result;
	}
}