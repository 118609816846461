<template>
  <router-view/>
</template>

<script>
	import Common from './utils/Common.js'
	import {
	  provide,
	  onMounted
	} from 'vue'
	export default {
	  name: 'App',
	  setup() {
		  //自定义方法
		  provide('Common', Common)
		 
	    onMounted(() => {
	      
	    })
	  },
	  components: {}
	}
</script>

<style>
	img, image, video {
	    max-width: 100%;
		border: 0;
		padding: 0;
		margin: 0;
		display: block !important;
	}
	

	*{padding: 0;margin: 0;}
	/* 设置滚动条的样式 */
	::-webkit-scrollbar {
	    width:0;
	}
	/* .van-nav-bar{
		background-color: #fc4b50 !important;
	}
	.van-nav-bar__title{
		color: white !important;
	} */
	
</style>
